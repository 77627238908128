<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <div
      class="dropdown bootstrap-select show-tick form-control"
      :class="{'is-invalid': errors.has('selectedLanguage') }"
    >
      <select
        @change="changedSelectPicker"
        class="form-control"
        id="language_select"
        v-model="selected"
        :value="selectedValue"
        data-size="5"
        :disabled="disabledSelect"
        v-validate="isValidate ? 'required' : ''"
        name="selectedLanguage"
        data-vv-as="Bahasa"
        :class="{'is-invalid': errors.has('selectedLanguage') }"
      >
        <option v-for="(value,name) in lang" :value="name" :key="name">{{ value }}</option>
      </select>
    </div>
    <div
      v-show="errors.first('selectedLanguage')"
      class="invalid-feedback"
    >{{ errors.first('selectedLanguage') }}</div>
  </div>
</template>

<script>

import lang from '../../constants/language';

export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true,
      lang: lang
    };
  },
  props: {
    selectedPicker: {},
    isValidate: { type: Boolean }
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getSelectPicker: function() {
      this.disabledSelect = false;
      $("#language_select").selectpicker();
      this.$nextTick(() => {
        $("#language_select").selectpicker("refresh");
      });
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
};
</script>
