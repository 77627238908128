<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">User</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body" v-if="isLoaded">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="user.id"
                />
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Login:</label>
                    <div class="col-lg-6">
                        <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isLoginLoading }'>
                        <input
                            type="text"
                            class="form-control"
                            v-model="user.login"
                            v-validate="'required|loginValid|max:50|uniqueLogin'"
                            data-vv-validate-on="blur"
                            data-vv-as="Login"
                            name="login"
                            :class="{'is-invalid': errors.has('login') }"/>
                        <div v-show="errors.first('login')" class="invalid-feedback">{{ errors.first('login') }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nama Depan:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="user.firstName"
                            v-validate="'required'"
                            data-vv-as="Nama Depan"
                            name="firstName"
                            :class="{'is-invalid': errors.has('firstName') }"/>
                        <div v-show="errors.first('firstName')" class="invalid-feedback">{{ errors.first('firstName') }}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Nama Belakang:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="user.lastName"
                            name="lastName"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Email:</label>
                    <div class="col-lg-6">
                        <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isEmailLoading }'>
                        <input
                            type="text"
                            class="form-control"
                            v-model="user.email"
                            v-validate="'required|email|uniqueEmail'"
                            data-vv-validate-on="blur"
                            data-vv-as="Email"
                            name="email"
                            :class="{'is-invalid': errors.has('email') }"/>
                        <div v-show="errors.first('email')" class="invalid-feedback">{{ errors.first('email') }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Bahasa:</label>
                    <div class="col-lg-6">
                        <language-select
                            :changedValue.sync="user.langKey"
                            :id-select-picker="'langKey' + user.id"
                            :selected-picker="user.langKey"
                            :is-validate="false"
                        ></language-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Profil :</label>
                    <div class="col-lg-6">
                        <authorities-select
                            v-model="user.authorities"
                            :validation="'required'"
                            :multiple="true"
                        ></authorities-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">User Grup :</label>
                    <div class="col-lg-6">
                        <user-group-select
                            v-model="user.userGroups"
                            :validation="'required'"
                            :multiple="true"
                        ></user-group-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Tenants :</label>
                    <div class="col-lg-6">
                       <vue-multi-select
                            v-model="user.tenantUsers"
                            :options="listTenants"
                            :validation="'required'"
                            :label="'name'"
                            :name="'SelectTenants'"
                            :trackBy="'id'"
                            :multiple="true"
                        >
                        </vue-multi-select>
                    </div>
                </div>
                <div v-if="!user.id">
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Password:</label>
                        <div class="col-lg-6">
                            <input
                                type="password"
                                class="form-control"
                                v-model="user.password"
                                v-validate="'required|min:4|max:50'"
                                data-vv-as="Password"
                                ref="password"
                                name="password"
                                :class="{'is-invalid': errors.has('password') }"/>
                            <div v-show="errors.first('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Konfirmasi Password:</label>
                        <div class="col-lg-6">
                            <input
                                type="password"
                                class="form-control"
                                v-validate="'required|confirmed:password'"
                                data-vv-as="Konfirmasi Password"
                                name="pass_conf"
                                :class="{'is-invalid': errors.has('pass_conf') }"/>
                            <div v-show="errors.first('pass_conf')" class="invalid-feedback">{{ errors.first('pass_conf') }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" v-if="user.id">
                    <label class="col-lg-4 col-form-label">Aktivasi:</label>
                    <div class="col-lg-6">
                        <div class="kt-checkbox-inline">
                            <label class="kt-checkbox">
                                <input
                                type="checkbox"
                                v-model="user.activated"
                                /> Aktif
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="m-auto p-5 d-flex flex-row h-100">
                <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                <h5 class="my-auto pl-5">Loading ...</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">Save</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import LanguageSelect from "../../_select/Language";
import AuthoritiesSelect from "../../_select/Authorities";
import UserGroupSelect from "../../_select/GroupUser";
import User from "../../../model/user-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");
const TenantUser = RepositoryFactory.get("tenantUser");
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import VueMultiSelect from '../../_select/VueMultiSelect.vue'

VeeValidate.setMode('passive');
export default {
    components: {
        LanguageSelect,
        AuthoritiesSelect,
        UserGroupSelect,
        VueMultiSelect
  },
  data() {
    return {
        user : new User(),
        isLoaded :  false,
        isLoginLoading: false,
        tempUserLogin : null,
        tempEmailLogin : null,
        isEmailLoading: false,
        listTenants: []
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    getTenants() {
         TenantUser.fetch()
         .then(res=> {
             this.listTenants = res.data
         })
         .catch(err => {
             console.log(err)
         })
    },
    clearData: function () {
        $("#" + this.modalName).modal("hide");
        this.$emit("update:showModal", false);
    },
    updateData: function (data) {
        this.user = data;
        this.tempUserLogin = this.user.login;
        this.tempEmailLogin = this.user.email;
        this.isLoaded = true;
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          if (vx.user.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data User akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.user);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data User akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.user);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await UserRepository.updateUser(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data User berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          var msg = ValidateErrorMessage.parseMessage(error);
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: msg,
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;
      await UserRepository.createUser(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data User berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          var msg = ValidateErrorMessage.parseMessage(error);
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: msg,
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    showToastError: function(message) {
        toastr.error(message);
    },
    checkIfLoginSame(value, data) {
        let user = false
        if(data.length == 0) return user
        data.forEach(element => {
            if(element.login.toLowerCase() == value.toLowerCase()) user = true
        });
        return user
    },
    checkLogin: function(value, resolve){
        var params = 'login%3A'+value;
        UserRepository.search(params).then((response) => {
            this.isLoginLoading = false;
            if (this.checkIfLoginSame(value, response.data)) {
                return resolve({
                    valid: false,
                    data: {
                    message: `Login ${value} sudah terpakai`
                    }
                });
            } else {
                return resolve({
                    valid: true
                });
            }

        }).catch(err => {
            this.isLoginLoading = false;
            var msg = (error.message) ? error.message : error;
            this.showToastError('check login : ' + msg);
            return resolve({
                valid: false,
                data : {
                    message: `Gagal Validasi Check Login`
                }
            })
        })
    },
    checkEmail: function(value, resolve){
        const split = value.split('@');
        var params = 'email%3A'+split[0];
        UserRepository.search(params).then((response) => {
            this.isEmailLoading = false;
            const temp = response.data.filter(x => x.email.toLowerCase() == value.toLowerCase());
            if (temp.length > 0) {
                return resolve({
                    valid: false,
                    data: {
                    message: `Email ${value} sudah terpakai`
                    }
                });
            } else {
                return resolve({
                    valid: true
                });
            }

        }).catch(err => {
            this.isEmailLoading = false;
            var msg = (error.message) ? error.message : error;
            this.showToastError('check email : ' + msg);
            return resolve({
                valid: false,
                data : {
                    message: `Gagal Validasi Check Email`
                }
            })
        })
    }
  },
  mounted() {
    this.getTenants();
    $("#" + this.modalName).modal();
    if (this.modalData) {
        this.updateData(this.modalData);
    } else {
        this.isLoaded = true;
    }
    const isUniqueLogin = value =>
        new Promise(resolve => {
            this.errors.remove('login');
            this.isLoginLoading = true
            if(this.user.id){
                if(value == this.tempUserLogin) {
                    this.isLoginLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkLogin(value, resolve);
                }
            } else {
                this.checkLogin(value, resolve);
            }
        });

    const isEmailUnique = value =>
        new Promise(resolve => {
            this.errors.remove('email');
            this.isEmailLoading = true;
            if(this.user.id){
                if(this.tempEmailLogin !== null && value.toLowerCase() == this.tempEmailLogin.toLowerCase()){
                    this.isEmailLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkEmail(value, resolve);
                }
            } else {
                this.checkEmail(value, resolve);
            }
        })
    Validator.extend("uniqueLogin", {
      validate: isUniqueLogin,
      getMessage: (field, params, data) => data.message
    });
    Validator.extend("uniqueEmail", {
      validate: isEmailUnique,
      getMessage: (field, params, data) => data.message
    });
    Validator.extend('loginValid', {
        getMessage: field => 'Login hanya dapat berisi huruf, angka dan karakter spesial seperti: . _ -  ',
        validate: value => {
           const regex = value.match(/^[_.A-Za-z0-9-]*$/g);
           return ( regex == null ) ? false : true;
        }
    });
  }
}
</script>

<style>

</style>
