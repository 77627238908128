<template>
    <div>
        <vue-multi-select
            v-model="selectedValue"
            :options="listOption"
            :validationName="validationName"
            :scope="(scope != null) ? scope : null"
            :validation="validation"
            :name="name"
            :trackBy="trackBy"
            :label="label"
            :multiple="multiple"
            :customLabel="customLabel"
            :select="select"
            :remove="remove"
            :loading="loading"
            :disabled="disabled"
            :placeholder="placeholder"
            :valueLabel="valueLabel"
        >
        </vue-multi-select>
    </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");
import VueMultiSelect from './VueMultiSelect.vue';

export default {
  provide() {
        return {
            $validator: this.$validator
        };
    },
  inject: ["$validator"],
  components: {
      VueMultiSelect,
  },
  data() {
    return {
        dataResponse:[],
        loading: false,
    };
  },
  model: {
        prop: 'value',
        event: 'change'
  },
  props: {
    value: [Array, Number, String, Object],
    options: {
        type:Array
    },
    validationName:{
        type:String,
        default:'Profile'
    },
    validation:{
        type: [String, Object],
        default:''
    },
    name:{
        type:String,
        default: 'SelectProfil'
    },
    scope: {
        type: String,
        default:null
    },
    multiple:{
        type: Boolean,
        default: false
    },
    trackBy: {
        type: String,
    },
    label:{
        type: String,
    },
    customLabel:{
        type:Function
    },
    select:{
        type: Function,
        default:() => {}
    },
    remove:{
        type: Function,
        default:() => {}
    },
    disabled:{
        type:Boolean,
        default:false
    },
    placeholder:{
        type: String
    },
    valueLabel:{
        type: String
    }

  },
  computed: {
    selectedValue: {
        get () {
            return this.value
        },
        set (newValue) {
            this.$emit('change', newValue);
        }
    },
    listOption:function() {
        return (this.options != undefined) ? this.options : this.dataResponse
    },
  },
  methods: {
    async getData() {
        this.loading=true;
        UserRepository.getAuthorities()
        .then(response => {
            this.dataResponse = response.data;
            this.loading=false;
        })
        .catch(error => {
            toastr.error('Autorities : ' + error);
        });
    },
  },
  mounted() {
    if (this.options == undefined) {
        this.getData();
    }
  }
};
</script>

